export default {
    ["/coach-senior"]                                                 : "/entrenador-senior",
    ["/coach-jeunes"]                                                 : "/entrenador-jovenes",
    ["/espace-membres"]                                               : "/espacio-miembros",
    ["/blog/category"]                                                : "/blog/categoria",
    ["/guide-800-exercices"]                                          : "/guia/800-ejercicios-futbol",
    ["/300-exercices-app"]                                            : "/300-ejercicios-app",
    ["/seances-coach-jeunes"]                                         : "/sesiones-de-entrenamiento-futbol/jovenes",
    ["/seances-coach-seniors"]                                        : "/sesiones-de-entrenamiento-futbol/seniors",
    ["/coach-de-senior"]                                              : "/entrenador-de-senior",
    ["/coach-de-jeunes"]                                              : "/entrenador-de-jovenes",
    ["/contact"]                                                      : "/contacto",
    ["/preparation-physique-coach"]                                   : "/entrenador-de-preparacion-fisica",
    ["/programmes"]                                                   : "/programas",
    ["/programmes/treve-hivernale"]                                   : "/programas/vacaciones-de-invierno",
    ["/programmes/treve-estivale-coach"]                              : "/programas/vacaciones-de-verano",
    ["/guide/200-exercices-jeux-reduits"]                             : "/guia/200-ejercicios-juegos-reducidos",
    ["/guide/management"]                                             : "/guia/gestion",
    ["/cycle-entrainement/seniors/100-pour-100-ballon"]               : "/ciclo-de-entrenamiento/seniors/100-por-100-balon",
    ["/cycle-entrainement/seniors/vitesse"]                           : "/ciclo-de-entrenamiento/seniors/velocidad",
    ["/cycle-entrainement/seniors/vitesse-gardien"]                   : "/ciclo-de-entrenamiento/seniors/velocidad-portero",
    ["/cycle-entrainement/seniors/vitesse-gardien-U15-U18"]           : "/ciclo-de-entrenamiento/seniors/velocidad-portero-sub15-sub18",
    ["/cycle-entrainement/seniors/endurance-puissance-futsal"]        : "/ciclo-de-entrenamiento/seniors/poder-de-resistencia-futsal",
    ["/cycle-entrainement/seniors/endurance-puissance-futsal-U15-U18"]: "/ciclo-de-entrenamiento/seniors/poder-de-resistencia-futsal-sub15-sub18",
    ["/cycle-entrainement/seniors/vitesse-futsal"]                    : "/ciclo-de-entrenamiento/seniors/velocidad-futsal",
    ["/cycle-entrainement/seniors/vitesse-futsal-U15-U18"]            : "/ciclo-de-entrenamiento/seniors/velocidad-futsal-sub15-sub18",
    ["/cycle-entrainement/seniors/endurance-puissance"]               : "/ciclo-de-entrenamiento/seniors/poder-de-resistencia",
    ["/cycle-entrainement/jeunes/endurance-puissance-U15-U18"]        : "/ciclo-de-entrenamiento/jovenes/poder-de-resistencia-sub15-sub18",
    ["/cycle-entrainement/jeunes/coordination-U8-U12"]                : "/ciclo-de-entrenamiento/jovenes/coordinacion-sub8-sub12",
    ["/cycle-entrainement/jeunes/vitesse-U8-U12"]                     : "/ciclo-de-entrenamiento/jovenes/velocidad-sub8-sub12",
    ["/cycle-entrainement/jeunes/vitesse-U12-14"]                     : "/ciclo-de-entrenamiento/jovenes/velocidad-sub12-sub14",
    ["/cycle-entrainement/jeunes/vitesse-U14-18"]                     : "/ciclo-de-entrenamiento/jovenes/velocidad-sub14-sub18",
    ["/cycle-entrainement/seniors/periodisation-tactique"]            : "/ciclo-de-entrenamiento/seniors/periodizacion-tactica",
    ["/les-emails"]                                                   : "/correos-electronicos",
    ["/preparation-physique-des-jeunes-footballeurs"]                 : "/preparacion-fisica-de-futbolistas-jovenes",
    ["/boutique-en-ligne"]                                            : "/tienda-en-linea",
    ["/bientot-disponible"]                                           : "/disponible-pronto",
    ["/mention-legal"]                                                : "/aviso-legal",
    ["/qui-sommes-nous"]                                              : "/sobre-nosotros",
  }